<template>
    <div class="studio">
        <div class="container studio-blocks" v-for="item in studioEntry" v-bind:key="item">
            <div class="row studio-block text padding-studio" v-bind:class="{ isAccordion: item.accordion }" v-if="item.type=='text'">
                <div class="col-12" v-html="item.text" v-bind:class="{ isAccordion: item.accordion, 'is-open': item['is-open'] }"></div>
                <a class="show-more style-none" @click="accordion(item)" v-bind:class="{  isAccordion: item.accordion, 'is-open': item['is-open'] }"><svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 18H24" stroke="black"/><path d="M3.35352 1.35156L11.8388 9.83684" stroke="black"/><path d="M11.0957 9.83984L19.581 1.35456" stroke="black"/></svg></a>
                <a class="show-less style-none" @click="accordion(item)" v-bind:class="{  isAccordion: item.accordion, 'is-open': item['is-open'] }"><svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 19H24" stroke="black"/><path d="M20.6465 9.64844L12.1612 1.16316" stroke="black"/><path d="M12.9043 1.16016L4.41902 9.64544" stroke="black"/></svg></a>
            </div>
            <div class="row studio-block" v-if="item.type=='image'">
                <div class="col-12">
                    <img :src="item.image" alt="" class="img-fluid">
                </div>
            </div>
            <div class="row studio-block heading padding-studio" v-if="item.type=='heading'">
                <div class="col-12">
                    <p>{{ item.title }}</p>
                </div>
            </div>
            <div class="row studio-block padding-studio textTwoColumns" v-if="item.type=='textTwoColumns'">
                <div class="col-12 col-md-6"  v-html="item.textLeft"></div>
                <div class="col-12 col-md-6 right"  v-html="item.textRight"></div>
            </div>
            <div class="row studio-block team padding-studio" v-if="item.type=='teamMember'">
                <div class="col-12">
                    <p>
                        {{ item.teamMemberName }} 
                        <span v-if="item.eMail">– </span>
                        <a v-if="item.eMail" :href="'mailto:' + item.eMail">{{ item.eMail }}</a>
                        <span v-if="item.text" class="accordion-arrows">
                            <a class="show-more style-none" @click="accordion(item)" v-bind:class="{  isAccordion: item.accordion, 'is-open': item['is-open'] }"><svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 18H24" stroke="black"/><path d="M3.35352 1.35156L11.8388 9.83684" stroke="black"/><path d="M11.0957 9.83984L19.581 1.35456" stroke="black"/></svg></a>
                            <a class="show-less style-none" @click="accordion(item)" v-bind:class="{  isAccordion: item.accordion, 'is-open': item['is-open'] }"><svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 19H24" stroke="black"/><path d="M20.6465 9.64844L12.1612 1.16316" stroke="black"/><path d="M12.9043 1.16016L4.41902 9.64544" stroke="black"/></svg></a>
                        </span>
                    </p>
                </div>
              <div class="col-12 isAccordion" v-html="item.text" v-bind:class="{ 'is-open': item['is-open'] }"></div>

          </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Studio',
    components: {},
    props: {
        studioEntry: Object
    },
    data () {
        return {};
    },
    methods: {
        accordion(item){
            item['is-open'] = item['is-open'] ? false : true;
            console.log(item['is-open']);
        },
    }
}
</script>
